<template>
<div class="header">
    <div class="inner-header flex">
       <img src="../../assets/img/SudaniWhiteYellow.png" alt=""/>
    </div>
    <div>
      <svg class="waves" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 24 150 28" preserveAspectRatio="none" shape-rendering="auto">
        <defs>
          <path id="gentle-wave" d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z"/>
        </defs>
        <g class="parallax">
          <use xlink:href="#gentle-wave" x="48" y="0" fill="rgba(255,255,255,0.7"/>
          <use xlink:href="#gentle-wave" x="48" y="3" fill="rgba(255,255,255,0.5)"/>
          <use xlink:href="#gentle-wave" x="48" y="5" fill="rgba(255,255,255,0.3)"/>
          <use xlink:href="#gentle-wave" x="48" y="7" fill="#fff" />
        </g>
      </svg>
    </div>
</div>  
<main id="content">
    <div class="content flex">
        <form @submit.prevent="onLogin()">
            <p class="" style="font-size:  18px; width:80%; font-weight: bold; ">قم بتسجيل الدخول واستمتع بتجربة فريدة من منصة تأق برس الاخبارية</p>
            <input type="text" class=" input" v-model="msisdn" autocomplete="off" placeholder="رقم الهاتف" onfocus="this.placeholder = ''" onblur="this.placeholder = 'رقم الهاتف'" />
            <!-- <button class="login-btn ">تسجيل الدخول</button> -->
            <button class="login-btn button">
                <span v-if="loading" class="px-1 ">جاري تسجيل الدخول</span> &nbsp;
                <div v-if="loading" class="spinner-border spinner-border-sm"></div>
                <span v-else class="">تسجيل الدخول</span>
            </button>
             <a href="http://sudanipay.com/subscription/tagpress/tagpress" target="_self" style="font-size:  18px; width:80%; font-weight: bold; inherit;color: #5d9cec;" class="btn btn-link button ">او اضغط هنا للاشتراك</a>
        </form>
        <div class="bottom-container"></div>
    </div>
</main>
</template>

<script>
// import axios from "axios";
import { HTTPSPA } from '@/Api/http-Spay';
import { useToast } from "vue-toastification";
// import { useCookie } from 'vue-cookie-next';
// import { useRouter, useRoute } from "vue-router";
// import { ref } from '@vue/reactivity';
export default {
  name: "LoginUsers",
  data() {
    return {
        msisdn: "",
        loading: false,
    };
  },
  mounted() {
    if (this.$cookie.isCookieAvailable("msisdn") && this.$cookie.isCookieAvailable("status")) {
        this.$router.push({ path: "/" })
    }
  },
  methods: {
    async onLogin() {
      const toast = useToast();
      if(this.msisdn.startsWith(9)) {
          toast.error("رقم الهاتف ليس سوداني", { timeout: 2500, });
      } else {
      if (this.msisdn != "") {
        const str = this.msisdn;
        if (str.substr(0, 1) === "0") {
          let number = str.substr(1);
          if (number.startsWith(1)) {
            this.phone = number;
          } else {
            toast.error("رقم الهاتف ليس سوداني", { timeout: 2500,});
          }
        } else if (str.substr(0, 1) === "2" && str.length === 12) {
          let number = str.substr(3);
          if (number.startsWith(1)) {
            this.phone = number;
          } else {
            toast.error("رقم الهاتف ليس سوداني", { timeout: 2500,});
          }
        } else if (str.substr(0, 1) === "1" && str.length === 9) {
          let number = str.substr();
          if (number.startsWith(1)) {
            this.phone = number;
          } else {
            toast.error("رقم الهاتف ليس سوداني", { timeout: 2500, });
          }
        } else if (str.length != 9) {
          toast.error("رقم الهاتف يجب ان يتكون من 10 ارقام", { timeout: 2500,});
        }
          if (this.phone.startsWith(1)) {
            this.loading = true;
            await HTTPSPA.post(`CheckLogin.php?username=249`+this.phone +`&serviceId=2222&operatorID=2`).then((res) => {
                if (res.data.status === 1 && res.data.MINTUES > 0) {
                    this.$cookie.setCookie("msisdn", "249" + this.phone, { expire: 60 * res.data.MINTUES, });
                    this.$cookie.setCookie("status", res.data.status, { expire: 60 * res.data.MINTUES,});
                    this.$cookie.setCookie("remming_minutes", res.data.MINTUES, { expire: 60 * res.data.MINTUES,});
                    toast.success("تم تسجيل الدخول استمتع بتجربة فريدة من تاق برس الاخبارية", { timeout: 2500 });
                    setTimeout(() => this.$router.push({ path: "/" }), 2500);
                } else if (res.data.status === 1 && res.data.MINTUES < 0) {
                    toast.success("تم تسجيل الدخول", { timeout: 2500, });
                    this.$cookie.setCookie("msisdn", "249" + this.phone, { expire: 60 * 120, });
                    this.$cookie.setCookie("status", res.data.status, { expire: 60 * 120,});
                    this.$cookie.setCookie("remming_minutes", res.data.MINTUES, { expire: 60 * 120, });
                } else if (res.data.status === 0 && res.data.responseCode === 118) {
                    this.$cookie.setCookie("msisdn", "249" + this.phone, { expire: 60 * 120, });
                    toast.warning("عفوا انت مشترك مسبقا ولم يتم تجديد اشتراكك", { timeout: 2500, });
                    setTimeout(() => this.$router.push({ path: "/" }), 2500);
                }else if (res.data.status === 0) {
                    toast.warning("عفوا انت لست مشترك في منصة تاق بريس الاخبارية", { timeout: 2500, });
                    setTimeout(() => this.$router.push({ path: "/" }), 2500);
                }else {
                    toast.info("عفوا انت لست مشترك في خدمة تاق بريس الاخبارية" , { timeout: 2500 });
                }
              }).catch((error) => {
                console.log(error);
              }).finally(() => {
                this.loading = false;
              });
          } else {
            toast.info("رقم الهاتف ليس سوداني", { timeout: 2500 });
          }
      } else {
        toast.info("الرجال ادخال رقم الهاتف", { timeout: 2500 });
      }
      }      
    },
  },
//     async setup() {
//         const msisdn = ref('');
//         const loading = ref(false);
//         const cookie = useCookie()
//         const toast = useToast();
//         const router = useRouter();
//         const route = useRoute();
//         const onLogin = () => {
//             HTTPSPA.post(`CheckLogin.php?username=249`+ msisdn +`&serviceId=2222&operatorID=2`).then((res) => {
//                 if (res.data.status === 1 && res.data.MINTUES > 0) {
//                     cookie.setCookie("msisdn", "249" + msisdn, { expire: 60 * res.data.MINTUES, });
//                     cookie.setCookie("status", res.data.status, { expire: 60 * res.data.MINTUES,});
//                     cookie.setCookie("remming_minutes", res.data.MINTUES, { expire: 60 * res.data.MINTUES,});
//                     toast.success("تم تسجيل الدخول استمتع بتجربة فريدة من تاق برس الاخبارية", { timeout: 2500 });
//                     setTimeout(() => router.push({ path: "/" }), 2500);
//                 } else if (res.data.status === 1 && res.data.MINTUES < 0) {
//                     toast.success("تم تسجيل الدخول", { timeout: 2500, });
//                     cookie.setCookie("msisdn", "249" + msisdn, { expire: 60 * 120, });
//                     cookie.setCookie("status", res.data.status, { expire: 60 * 120,});
//                     cookie.setCookie("remming_minutes", res.data.MINTUES, { expire: 60 * 120, });
//                 } else if (res.data.status === 0 && res.data.responseCode === 118) {
//                     cookie.setCookie("msisdn", "249" + msisdn, { expire: 60 * 120, });
//                     toast.warning("عفوا انت مشترك مسبقا ولم يتم تجديد اشتراكك", { timeout: 2500, });
//                     setTimeout(() => router.push({ path: "/" }), 2500);
//                 } else if (res.data.status === 0) {
//                     toast.warning("عفوا انت لست مشترك في منصة تاق بريس الاخبارية", { timeout: 2500, });
//                     setTimeout(() => router.push({ path: "/" }), 2500);
//                 }else {
//                     toast.info("عفوا انت لست مشترك في خدمة تاق بريس الاخبارية" , { timeout: 2500 });
//                 }
//             });
//       }
//       return { onLogin, loading, msisdn }
//   }
//   methods: {
//     async onLogin() {
//         const toast = useToast();
//           let input = document.querySelector(".input");
//           let button = document.querySelector(".button");
//           button.disabled = true;
//           input.addEventListener("change", stateHandle);

//           function stateHandle() {
//             if(document.querySelector(".input").value === "") {
//                button.disabled = true;
//             } else {
//                button.disabled = false;
//             }
//           }
//         if (this.msisdn != "") {
//                 var phoneno = (/^[(]{0,1}[0-9]{3}[)]{0,1}[-\s]{0,1}[0-9]{3}[-\s]{0,1}[0-9]{4}$/);
//                 if (this.msisdn.match(phoneno)) {
//                     if (this.msisdn.startsWith("01")) {
//                         let phone = this.msisdn.substr(1);
//                         this.loading = true
//                         await HTTPSPA.post("CheckLogin.php?username=249"+ phone +"&serviceId=2222&operatorID=2").then((res) => {
//                             if (res.data.status === 1 && res.data.MINTUES > 0) {
//                                 this.$cookie.setCookie("msisdn", "249" + phone, { expire: 60 * res.data.MINTUES, });
//                                 this.$cookie.setCookie("status", res.data.status, { expire: 60 * res.data.MINTUES,});
//                                 this.$cookie.setCookie("remming_minutes", res.data.MINTUES, { expire: 60 * res.data.MINTUES,});
//                                 toast.success("تم تسجيل الدخول استمتع بتجربة فريدة من تاق برس الاخبارية", { timeout: 2500 });
//                                 setTimeout(() => this.$router.push({ path: "/" }), 2500);
//                             } else if (res.data.status === 1 && res.data.MINTUES < 0) {
//                                 toast.success("تم تسجيل الدخول", { timeout: 2500, });
//                                 this.$cookie.setCookie("msisdn", "249" + phone, { expire: 60 * 120, });
//                                 this.$cookie.setCookie("status", res.data.status, { expire: 60 * 120,});
//                                 this.$cookie.setCookie("remming_minutes", res.data.MINTUES, { expire: 60 * 120, });
//                             } else if (res.data.status === 0 && res.data.responseCode === 118) {
//                                 this.$cookie.setCookie("msisdn", "249" + phone, { expire: 60 * 120, });
//                                 toast.warning("عفوا انت مشترك مسبقا ولم يتم تجديد اشتراكك", { timeout: 2500, });
//                                 setTimeout(() => this.$router.push({ path: "/" }), 2500);
//                             }else if (res.data.status === 0) {
//                                 toast.warning("عفوا انت لست مشترك في منصة تاق بريس الاخبارية", { timeout: 2500, });
//                                 setTimeout(() => this.$router.push({ path: "/" }), 2500);
//                             }else {
//                                 toast.info("عفوا انت لست مشترك في خدمة تاق بريس الاخبارية" , { timeout: 2500 });
//                             }
//                         }).catch((error) => {
//                            console.log(error);
//                         }).finally(() => {
//                            this.loading = false;
//                         });
//                     } else {
//                         toast.info("رقم الهاتف ليس سوداني" , { timeout: 2500 });
//                     }
//                 } else {
//                     toast.info("رقم الهاتف يجب ان يتكون من 10 رقم ويبدا بي 01 " , { timeout: 2500 });
//                 }
//             } else {
//                 toast.info("الرجال ادخال رقم الهاتف" , { timeout: 2500 });
//             }
//     },
//   },
};
</script>

<style scoped>
    .pointer {
       cursor: pointer;
    }
    h3 {
       /* font-family: 'Cairo', sans-serif; */
       font-weight:600;
       letter-spacing: 2px;
       font-size:32px;
    }
    p {
       /* font-family: 'Cairo', sans-serif; */
       letter-spacing: 0.1px;
       font-size:14px;
       color: #333333;
    }
    .header {
        position:relative;
        text-align:center;
        background: linear-gradient(60deg, rgb(18, 5, 68) 0%, rgba(20, 0, 255, 0.48) 100%);
        color:white;
    }
    .inner-header img{
        width:15%;
        position: relative;
        margin-top: 6%;
    }
    .inner-header {
        height:20vh;
        width:100%;
        margin: 0;
        padding: 0;
    }
    .flex { /*Flexbox for containers*/
        display: flex;
        flex-direction:column;
        justify-content: center;
        align-items: center;
        text-align: center;
    }
    .bottom-container{
        display:flex;
        align-items:center;
    }
    a{
        text-decoration:none;
        margin: 0px 5px;
        /* font-family: 'Cairo', sans-serif; */
        letter-spacing:0px;
        font-size:14px;
        font-weight:600;
        color: #ffffff;
    }

    .waves {
        position:relative;
        width: 100%;
        height:15vh;
        margin-bottom:-7px; /*Fix for safari gap*/
        min-height:100px;
        max-height:150px;
    }

    .content {
        position:relative;
        height:100%;
        text-align:center;
        background-color: white;
    }

    /* Animation */
    .parallax > use {
        animation: move-forever 25s cubic-bezier(.55,.5,.45,.5)     infinite;
    }
    .parallax > use:nth-child(1) {
        animation-delay: -2s;
        animation-duration: 7s;
    }
    .parallax > use:nth-child(2) {
        animation-delay: -3s;
        animation-duration: 10s;
    }
    .parallax > use:nth-child(3) {
        animation-delay: -4s;
        animation-duration: 13s;
    }
    .parallax > use:nth-child(4) {
        animation-delay: -5s;
        animation-duration: 20s;
    }
    @keyframes move-forever {
        0% {
            transform: translate3d(-90px,0,0);
        }
        100% { 
            transform: translate3d(85px,0,0);
        }
    }
            
    form{
        display:flex;
        width:90%;
        justify-content:center;
        align-items:center;
        flex-direction:column;
        padding:10px;
        margin:10%;
    }
    input{
        width:40%;
        padding:8px;
        margin:10px;
        height:45px;
        font-size:1rem;;
        border:none;
        box-shadow: 0px 0px 15px 0px rgb(0 0 0 / 20%);
        border-radius:7px;
        outline:none;
        text-align:center;
        /* font-family: 'Cairo', sans-serif; */
        color: #2A4A8B;
    }

    .login-btn{
        font-size:1rem;
        border:none;
        height:44px;
        width:40%;
        margin:20px 10px;
        border-radius:7px;
        background-color:#2A4A8B;
        /* font-family: 'Cairo', sans-serif; */
        color:#ffffff
    }

    button:hover {
        box-shadow: 0px 0px 15px 0px rgb(0 0 0 / 30%);
    }

    /*Shrinking for mobile*/
    @media (max-width: 768px) {
        .form{
            max-width:80vh;
        }
        .waves {
            height:40px;
            min-height:40px;
        }
        .content {
            height:50vh;
        }
        h1 {
            font-size:24px;
        }
        .inner-header img {
            width: 20%;
            position: relative;
            margin-top: 6%;
        }
        input{
            width: 60%;
        } 
        .login-btn{
            width: 50%;
            margin: 10px 10px;
        }
    }

    @media (max-width: 516px) {
        .form{
            max-width:50vh;
        }
        .waves {
            height:40px;
            min-height:40px;
        }
        .content {
            height:50vh;
        }
        h1 {
            font-size:24px;
        }
        .inner-header img {
            width: 40%;
        }
        input{
            width: 90%;
        }   
        .login-btn{
            width: 90%;
            margin: 10px 10px;
        }
    }
</style>